import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"



import { FullPool } from '@/services/balancer/subgraph/types';

import Pool from './Pool';
import BoostedPool from './BoostedPool';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  loading: boolean;
};

/**
 * PROPS
 */


export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  loading: boolean;
}) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (props.pool.onchain?.linearPools)
    ? (_openBlock(), _createBlock(_unref(BoostedPool), {
        key: 0,
        pool: props.pool
      }, null, 8, ["pool"]))
    : (_openBlock(), _createBlock(_unref(Pool), {
        key: 1,
        pool: props.pool
      }, null, 8, ["pool"]))
}
}

})