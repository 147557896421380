export const EXTERNAL_LINKS = {
  Balancer: {
    Home: 'https://koil.finance'
  },
  Gauntlet: {
    Home: 'https://gauntlet.network'
  },
  Fuse: {
    Wallets: 'https://fuse.io/about'
  }
};
