import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3b56f842")
const _hoisted_1 = {
  key: 0,
  class: "col-span-3"
}
const _hoisted_2 = { class: "relative center-col-mh" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-11 lg:col-span-3"
}
_popScopeId()

import { computed, ref, watch, nextTick, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';

import ChooseWeights from '@/components/cards/CreatePool/ChooseWeights.vue';
import PoolSummary from '@/components/cards/CreatePool/PoolSummary.vue';
import PoolFees from '@/components/cards/CreatePool/PoolFees.vue';
import SimilarPools from '@/components/cards/CreatePool/SimilarPools.vue';
import InitialLiquidity from '@/components/cards/CreatePool/InitialLiquidity.vue';
import SimilarPoolsCompact from '@/components/cards/CreatePool/SimilarPoolsCompact.vue';
import PreviewPool from '@/components/cards/CreatePool/PreviewPool.vue';
import BalVerticalSteps from '@/components/_global/BalVerticalSteps/BalVerticalSteps.vue';
import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import Col3Layout from '@/components/layouts/Col3Layout.vue';
import UnknownTokenPriceModal from '@/components/modals/UnknownTokenPrice/UnknownTokenPriceModal.vue';
import TokenPrices from '@/components/cards/CreatePool/TokenPrices.vue';

import anime from 'animejs';

import useApp from '@/composables/useApp';
import usePoolCreation, {
  POOL_CREATION_STATE_KEY,
  POOL_CREATION_STATE_VERSION
} from '@/composables/pools/usePoolCreation';
import { StepState } from '@/types';
import useBreakpoints from '@/composables/useBreakpoints';
import useAlerts from '@/composables/useAlerts';
import { lsGet } from '@/lib/utils';
import useTokens from '@/composables/useTokens';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const accordionWrapper = ref<HTMLElement>();
const hasCompletedMountAnimation = ref(false);
const prevWrapperHeight = ref(0);
const isUnknownTokenModalVisible = ref(false);

/**
 * COMPOSABLES
 */
const { appLoading } = useApp();
const {
  activeStep,
  similarPools,
  hasInjectedToken,
  hasRestoredFromSavedState,
  tokensList,
  seedTokens,
  setActiveStep,
  setRestoredState,
  importState,
  totalLiquidity,
  resetPoolCreationState
} = usePoolCreation();
const { removeAlert } = useAlerts();
const { t } = useI18n();
const { upToLargeBreakpoint } = useBreakpoints();
const {
  dynamicDataLoading,
  priceFor,
  tokens,
  injectTokens,
  injectedPrices,
  loading: isLoadingTokens
} = useTokens();

/**
 * LIFECYCLE
 */
onBeforeMount(async () => {
  removeAlert('return-to-pool-creation');
  if (accordionWrapper.value) {
    anime.set(accordionWrapper.value, {
      opacity: 0
    });
  }
  let previouslySavedState = lsGet(
    POOL_CREATION_STATE_KEY,
    null,
    POOL_CREATION_STATE_VERSION
  );
  if (activeStep.value === 0 && previouslySavedState !== null) {
    // need to make sure to inject any tokens that were chosen
    previouslySavedState = JSON.parse(previouslySavedState);
    importState(previouslySavedState);
    setRestoredState(true);
    await nextTick();
    setActiveStep(previouslySavedState.activeStep);
  }
  // make sure to inject any custom tokens we cannot inject
  // after tokens have finished loading as it will attempt to
  // inject 'known' tokens too, as during mount, tokens are still loading
  injectUnknownPoolTokens();
});

/**
 * COMPUTED
 */
const doSimilarPoolsExist = computed(() => similarPools.value.length > 0);
const validTokens = computed(() => tokensList.value.filter(t => t !== ''));

const unknownTokens = computed(() => {
  return validTokens.value.filter(token => {
    return priceFor(token) === 0 || injectedPrices.value[token];
  });
});

const hasUnknownToken = computed(() =>
  validTokens.value.some(t => priceFor(t) === 0)
);

const steps = computed(() => [
  {
    tooltip: 'Choose tokens & weights',
    state: getStepState(0),
    label: 1
  },
  {
    tooltip: 'Set pool fees',
    state: getStepState(1),
    label: 2
  },
  {
    tooltip: 'Similar pools',
    state: StepState.Warning,
    isVisible: doSimilarPoolsExist.value && activeStep.value === 2
  },
  {
    tooltip: 'Set initial liquidity',
    state: getStepState(3),
    label: 3
  },
  {
    tooltip: 'Confirm pool creation',
    state: getStepState(4),
    label: 4
  }
]);

const initialAnimateProps = computed(() => ({
  opacity: 0,
  translateY: '100px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0
}));

const entryAnimateProps = computed(() => ({
  opacity: 1,
  translateY: hasRestoredFromSavedState.value ? '116px' : '0px',
  position: 'relative'
}));

const exitAnimateProps = computed(() => ({
  opacity: 0,
  translateY: '-100px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0
}));

/**
 * FUNCTIONS
 */
function getStepState(idx: number) {
  if (activeStep.value === idx) {
    return StepState.Active;
  } else {
    if (activeStep.value > idx) {
      return StepState.Completed;
    } else {
      return StepState.Todo;
    }
  }
}

function setWrapperHeight(dimensions?: { width: number; height: number }) {
  // need to transform the accordion as everything is absolutely
  // positioned inside the AnimateHeight component
  if (dimensions?.height) prevWrapperHeight.value = dimensions.height;
  let mobileOffset = 20;

  anime({
    targets: accordionWrapper.value,
    translateY: `${prevWrapperHeight.value + mobileOffset}px`,
    easing: 'spring(0.4, 500, 9, 0)',
    complete: () => {
      if (!hasCompletedMountAnimation.value) {
        anime({
          targets: accordionWrapper.value,
          opacity: 1,
          complete: () => {
            hasCompletedMountAnimation.value = true;
          }
        });
      }
    }
  });
}

function handleNavigate(stepIndex: number) {
  if (hasRestoredFromSavedState.value) {
    setRestoredState(false);
  }
  setActiveStep(stepIndex);
}

function handleReset() {
  resetPoolCreationState();
  setActiveStep(0);
}

function handleUnknownModalClose() {
  isUnknownTokenModalVisible.value = false;
}

function showUnknownTokenModal() {
  isUnknownTokenModalVisible.value = true;
}

function injectUnknownPoolTokens() {
  if (!isLoadingTokens.value) {
    const uninjectedTokens = seedTokens.value
      .filter(seedToken => tokens.value[seedToken.tokenAddress] === undefined)
      .map(seedToken => seedToken.tokenAddress);
    injectTokens(uninjectedTokens);
  }
}

/**
 * WATCHERS
 */
watch([hasInjectedToken, totalLiquidity], () => {
  setWrapperHeight();
});

// can handle the behaviour to show the unknown token modal
// on next step here, rather than having to clutter the
// usePoolCreation composable further
watch(activeStep, () => {
  if (hasUnknownToken.value && !hasRestoredFromSavedState.value) {
    showUnknownTokenModal();
  }
});

// make sure to inject any custom tokens we cannot inject
// after tokens have finished loading as it will attempt to
// inject 'known' tokens too, as during mount, tokens are still loading
watch(isLoadingTokens, () => {
  injectUnknownPoolTokens();
});

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalAlert = _resolveComponent("BalAlert")!
  const _component_BalAccordion = _resolveComponent("BalAccordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Col3Layout, {
      offsetGutters: "",
      mobileHideGutters: "",
      class: "mt-8"
    }, {
      gutterLeft: _withCtx(() => [
        (!_unref(upToLargeBreakpoint))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (!_unref(appLoading))
                ? (_openBlock(), _createBlock(_component_BalStack, {
                    key: 0,
                    vertical: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(BalVerticalSteps, {
                        title: "Create a weighted pool steps",
                        steps: _unref(steps),
                        onNavigate: handleNavigate
                      }, null, 8, ["steps"]),
                      _createVNode(AnimatePresence, {
                        isVisible: 
              _unref(doSimilarPoolsExist) && _unref(activeStep) === 0 && _unref(validTokens).length
            
                      }, {
                        default: _withCtx(() => [
                          _createVNode(SimilarPoolsCompact)
                        ]),
                        _: 1
                      }, 8, ["isVisible"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      gutterRight: _withCtx(() => [
        (!_unref(upToLargeBreakpoint))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (!_unref(appLoading))
                ? (_openBlock(), _createBlock(_component_BalStack, {
                    key: 0,
                    vertical: "",
                    spacing: "base"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(PoolSummary),
                      _createVNode(TokenPrices, { toggleUnknownPriceModal: showUnknownTokenModal })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AnimatePresence, {
            isVisible: _unref(hasRestoredFromSavedState) && !_unref(appLoading),
            unmountInstantly: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalAlert, {
                type: "warning",
                class: "mb-4",
                title: _ctx.$t('createAPool.recoveredState')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('createAPool.recoveredStateInfo')) + " ", 1),
                  _createElementVNode("button", {
                    onClick: handleReset,
                    class: "font-semibold text-red-500"
                  }, _toDisplayString(_ctx.$t('clickHere')), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _: 1
          }, 8, ["isVisible"]),
          _createVNode(AnimatePresence, {
            isVisible: 
          !_unref(appLoading) && _unref(activeStep) === 0 && !_unref(hasRestoredFromSavedState)
        ,
            initial: _unref(initialAnimateProps),
            animate: _unref(entryAnimateProps),
            exit: _unref(exitAnimateProps)
          }, {
            default: _withCtx(() => [
              _createVNode(ChooseWeights, { "onUpdate:height": setWrapperHeight })
            ]),
            _: 1
          }, 8, ["isVisible", "initial", "animate", "exit"]),
          _createVNode(AnimatePresence, {
            isVisible: !_unref(appLoading) && _unref(activeStep) === 1,
            initial: _unref(initialAnimateProps),
            animate: _unref(entryAnimateProps),
            exit: _unref(exitAnimateProps),
            onUpdateDimensions: setWrapperHeight
          }, {
            default: _withCtx(() => [
              _createVNode(PoolFees, { "onUpdate:height": setWrapperHeight })
            ]),
            _: 1
          }, 8, ["isVisible", "initial", "animate", "exit"]),
          _createVNode(AnimatePresence, {
            isVisible: !_unref(appLoading) && _unref(activeStep) === 2 && _unref(similarPools).length > 0,
            initial: _unref(initialAnimateProps),
            animate: _unref(entryAnimateProps),
            exit: _unref(exitAnimateProps),
            onUpdateDimensions: setWrapperHeight
          }, {
            default: _withCtx(() => [
              _createVNode(SimilarPools)
            ]),
            _: 1
          }, 8, ["isVisible", "initial", "animate", "exit"]),
          _createVNode(AnimatePresence, {
            isVisible: !_unref(appLoading) && _unref(activeStep) === 3,
            initial: _unref(initialAnimateProps),
            animate: _unref(entryAnimateProps),
            exit: _unref(exitAnimateProps),
            onUpdateDimensions: setWrapperHeight
          }, {
            default: _withCtx(() => [
              _createVNode(InitialLiquidity, { "onUpdate:height": setWrapperHeight })
            ]),
            _: 1
          }, 8, ["isVisible", "initial", "animate", "exit"]),
          _createVNode(AnimatePresence, {
            isVisible: !_unref(appLoading) && _unref(activeStep) === 4 && !_unref(dynamicDataLoading),
            initial: _unref(initialAnimateProps),
            animate: _unref(entryAnimateProps),
            exit: _unref(exitAnimateProps),
            onUpdateDimensions: setWrapperHeight
          }, {
            default: _withCtx(() => [
              _createVNode(PreviewPool)
            ]),
            _: 1
          }, 8, ["isVisible", "initial", "animate", "exit"]),
          (_unref(upToLargeBreakpoint))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref: accordionWrapper,
                class: "pb-24"
              }, [
                _createVNode(_component_BalAccordion, {
                  sections: [
            { title: _unref(t)('poolSummary'), id: 'pool-summary' },
            { title: _unref(t)('tokenPrices'), id: 'token-prices' }
          ]
                }, {
                  "pool-summary": _withCtx(() => [
                    _createVNode(PoolSummary)
                  ]),
                  "token-prices": _withCtx(() => [
                    _createVNode(TokenPrices)
                  ]),
                  _: 1
                }, 8, ["sections"])
              ], 512))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(UnknownTokenPriceModal, {
      onClose: handleUnknownModalClose,
      isVisible: isUnknownTokenModalVisible.value,
      unknownTokens: _unref(unknownTokens)
    }, null, 8, ["isVisible", "unknownTokens"])
  ], 64))
}
}

})