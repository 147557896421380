import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-05d6a1c4")
const _hoisted_1 = { class: "font-bold dark:text-gray-300" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = {
  class: "w-full flex justify-between p-2 px-4",
  style: {"background":"#F6EDD2","border-bottom":"1px solid #D8CEB5"}
}
const _hoisted_4 = { class: "relative w-full" }
const _hoisted_5 = { class: "w-full flex justify-between" }
_popScopeId()

import { computed, onMounted, ref, nextTick, onBeforeUpdate } from 'vue';

import TokenWeightInput from '@/components/inputs/TokenInput/TokenWeightInput.vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useBreakpoints from '@/composables/useBreakpoints';
import usePoolCreation, {
  PoolSeedToken
} from '@/composables/pools/usePoolCreation';
import useTokens from '@/composables/useTokens';

import { configService } from '@/services/config/config.service';

import { sum, sumBy, uniqueId } from 'lodash';
import anime from 'animejs';
import { bnum } from '@/lib/utils';
import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import useWeb3 from '@/services/web3/useWeb3';
import { useI18n } from 'vue-i18n';


export default _defineComponent({
  emits: ['update:height', 'trigger:alert'],
  setup(__props, { emit }) {



const emptyTokenWeight: PoolSeedToken = {
  tokenAddress: '',
  weight: 0,
  id: '0',
  isLocked: false,
  amount: '0'
};

/**
 * COMPOSABLES
 */
const {
  seedTokens,
  updateTokenWeights,
  proceed,
  tokensList,
  totalLiquidity,
  hasInjectedToken,
  acceptCustomTokenDisclaimer,
  acceptedCustomTokenDisclaimer
} = usePoolCreation();
const { upToLargeBreakpoint } = useBreakpoints();
const { fNum2 } = useNumbers();
const { nativeAsset, tokens } = useTokens();
const { isWalletReady, toggleWalletSelectModal } = useWeb3();
const { t } = useI18n();

/**
 * STATE
 */
const networkName = configService.network.name;

const tokenWeightListWrapper = ref<HTMLElement>();
const addTokenRowElement = ref<HTMLElement>();
const totalsRowElement = ref<HTMLElement>();
const seedTokenElements = ref<HTMLElement[]>([]);
const cardWrapper = ref<HTMLElement>();
const wrapperHeight = ref(0);
const cardWrapperHeight = ref(0);

/**
 * COMPUTED
 */
const tokenWeightItemHeight = computed(() =>
  upToLargeBreakpoint.value ? 56 : 64
);

const zeroWeightToken = computed(() => {
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  const zeroWeightToken = validTokens.find(t => t.weight === 0);
  if (zeroWeightToken) {
    return tokens.value[zeroWeightToken.tokenAddress];
  }
  return null;
});

const walletLabel = computed(() => {
  if (!isWalletReady.value) {
    return t('connectWallet');
  }
  if (showLiquidityAlert.value) {
    return t('continueAnyway');
  }
  return t('next');
});

const totalAllocatedWeight = computed(() => {
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  const validPercentage = sumBy(validTokens, 'weight');
  return validPercentage.toFixed(2);
});

const totalWeight = computed(() => {
  const pct = sumBy(seedTokens.value, 'weight');
  return pct.toFixed(2);
});

const isProceedDisabled = computed(() => {
  if (!isWalletReady.value) return false;
  if (Number(totalAllocatedWeight.value) !== 100) return true;
  if (seedTokens.value.length < 2) return true;
  if (zeroWeightToken.value) return true;
  if (hasInjectedToken.value && !acceptedCustomTokenDisclaimer.value)
    return true;
  return false;
});

const showLiquidityAlert = computed(() => {
  const validTokens = seedTokens.value.filter(t => t.tokenAddress !== '');
  return totalLiquidity.value.lt(20000) && validTokens.length >= 2;
});

const excludedTokens = computed((): string[] => {
  return [nativeAsset.address, ...tokensList.value];
});

const maxTokenAmountReached = computed(() => {
  return seedTokens.value.length >= 8;
});

const progressBarColor = computed(() => {
  if (
    Number(totalAllocatedWeight.value) > 100 ||
    Number(totalAllocatedWeight.value) <= 0
  ) {
    return 'red';
  }
  return 'green';
});

const weightColor = computed(() => {
  if (Number(totalWeight.value) > 100 || Number(totalWeight.value) <= 0) {
    return 'text-red-500';
  }
  return 'text-gray-800';
});

/**
 * LIFECYCLE
 */
onMounted(async () => {
  // retrieving height causes reflow, get the height of the wrapper once
  // and manually uptick it when we add items to prevent double reflow during anim
  wrapperHeight.value = tokenWeightListWrapper.value?.offsetHeight || 0;
  cardWrapperHeight.value = cardWrapper.value?.offsetHeight || 0;

  // add in the first token list item
  if (!seedTokens.value.length) {
    addTokenToPool();
    addTokenToPool();
  } else {
    await animateHeight(seedTokens.value.length);
  }
  // wait for vue to reflect the changes of above
  await nextTick();
  distributeWeights();
});

onBeforeUpdate(() => {
  seedTokenElements.value = [];
});

/**
 * FUNCTIONS
 */
function handleWeightChange(weight: string, id: number) {
  const tokenWeight = seedTokens.value[id];
  tokenWeight.weight = Number(weight);

  distributeWeights();
}

function handleAddressChange(address: string, id: number) {
  const tokenWeight = seedTokens.value[id];
  tokenWeight.tokenAddress = address;
}

function handleLockedWeight(isLocked: boolean, id: number) {
  const tokenWeight = seedTokens.value[id];
  tokenWeight.isLocked = isLocked;
  distributeWeights();
}

async function animateHeight(offset = 0) {
  // animate the height initially
  emit('update:height', {
    height:
      (cardWrapper.value?.offsetHeight || 0) +
      tokenWeightItemHeight.value * offset
  });
  anime({
    targets: tokenWeightListWrapper.value,
    height: `${wrapperHeight.value + tokenWeightItemHeight.value * offset}px`,
    complete: () => {
      emit('update:height', {
        height: cardWrapper.value?.offsetHeight || 0
      });
    }
  });
  wrapperHeight.value += tokenWeightItemHeight.value * offset;
  // to avoid reflow we are going to transform the totals + add token
  // down instead of having the new token weight item shift them
  anime({
    targets: [totalsRowElement.value, addTokenRowElement.value],
    translateY: `${tokenWeightItemHeight.value * seedTokens.value.length}px`,
    easing: 'spring(0.4, 500, 9, 0)'
  });
  await nextTick();
  // get the last added token weight element
  seedTokenElements.value.forEach((seedTokenElement, i) => {
    anime.set(seedTokenElement, {
      left: 0,
      right: 0,
      top: `${tokenWeightItemHeight.value * i}px`
    });
  });
}

async function addTokenToPool() {
  const newWeights: PoolSeedToken[] = [
    ...seedTokens.value,
    { ...emptyTokenWeight, id: uniqueId() } as PoolSeedToken
  ];
  updateTokenWeights(newWeights);
  await animateHeight(1);
  distributeWeights();
}

function distributeWeights() {
  // get all the locked weights and sum those bad boys
  let lockedPct = sum(
    seedTokens.value.filter(w => w.isLocked).map(w => w.weight / 100)
  );
  // makes it so that new allocations are set as 0
  if (lockedPct > 1) lockedPct = 1;
  const pctAvailableToDistribute = bnum(1).minus(lockedPct);
  const unlockedWeights = seedTokens.value.filter(w => !w.isLocked);
  const evenDistributionWeight = pctAvailableToDistribute.div(
    unlockedWeights.length
  );

  const error = pctAvailableToDistribute.minus(
    evenDistributionWeight.times(unlockedWeights.length)
  );
  const isErrorDivisible = error.mod(unlockedWeights.length).eq(0);
  const distributableError = isErrorDivisible
    ? error.div(unlockedWeights.length)
    : error;

  const normalisedWeights = unlockedWeights.map((_, i) => {
    const evenDistributionWeight4DP = Number(evenDistributionWeight.toFixed(4));
    const errorScaledTo4DP = Number(distributableError.toString()) * 1e14;
    if (!isErrorDivisible && i === 0) {
      return evenDistributionWeight4DP + errorScaledTo4DP;
    } else if (isErrorDivisible) {
      return evenDistributionWeight4DP + errorScaledTo4DP;
    } else {
      return evenDistributionWeight4DP;
    }
  });

  unlockedWeights.forEach((tokenWeight, i) => {
    tokenWeight.weight = Number((normalisedWeights[i] * 100).toFixed(2));
  });
}

function addTokenListElementRef(el: HTMLElement) {
  // const filteredElements = seedTokenElements.value.filter(e => e !== null);
  if (!seedTokenElements.value.includes(el) && el) {
    seedTokenElements.value.push(el);
  }
}

async function handleRemoveToken(index: number) {
  updateTokenWeights(seedTokens.value.filter((_, i) => i !== index));
  await nextTick();
  seedTokenElements.value = seedTokenElements.value.filter(
    (_, i) => i !== index
  );
  distributeWeights();
  animateHeight(-1);
}

function handleProceed() {
  if (!isWalletReady.value) {
    toggleWalletSelectModal(true);
  } else {
    proceed();
  }
}

function onAlertMountChange() {
  emit('update:height', {
    height: cardWrapper.value?.offsetHeight || 0
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalProgressBar = _resolveComponent("BalProgressBar")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_BalAlert = _resolveComponent("BalAlert")!

  return (_openBlock(), _createElementBlock("div", {
    ref: cardWrapper,
    class: "mb-16"
  }, [
    _createVNode(_component_BalCard, {
      shadow: "xl",
      noBorder: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BalStack, {
          vertical: "",
          spacing: "sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalStack, {
              vertical: "",
              spacing: "xs"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t('createAPool.chooseTokenWeights')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BalCard, {
              class: "mb-3",
              shadow: "none",
              noPad: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", { ref: tokenWeightListWrapper }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('token')), 1),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('weight')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(seedTokens), (token, i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "absolute w-full",
                          key: `tokenweight-${token.id}`,
                          ref: addTokenListElementRef
                        }, [
                          _createVNode(AnimatePresence, { isVisible: "" }, {
                            default: _withCtx(() => [
                              _createVNode(TokenWeightInput, {
                                weight: _unref(seedTokens)[i].weight,
                                "onUpdate:weight": [($event: any) => (_unref(seedTokens)[i].weight = $event), data => handleWeightChange(data, i)],
                                address: _unref(seedTokens)[i].tokenAddress,
                                "onUpdate:address": [($event: any) => (_unref(seedTokens)[i].tokenAddress = $event), data => handleAddressChange(data, i)],
                                "onUpdate:isLocked": data => handleLockedWeight(data, i),
                                onDelete: () => handleRemoveToken(i),
                                noRules: "",
                                noMax: "",
                                excludedTokens: _unref(excludedTokens)
                              }, null, 8, ["weight", "onUpdate:weight", "address", "onUpdate:address", "onUpdate:isLocked", "onDelete", "excludedTokens"])
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", {
                      class: "p-3 border-b border-t",
                      ref: addTokenRowElement,
                      style: {"background":"#F6EDD2","border-color":"#D8CEB5"}
                    }, [
                      _createVNode(_component_BalBtn, {
                        disabled: _unref(maxTokenAmountReached),
                        onClick: addTokenToPool,
                        outline: "",
                        style: _normalizeStyle([{"color":"black","border":"1px solid #D8CEB5","border-radius":"10px","box-sizing":"border-box","box-shadow":"0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.02)"}, {
                    background: _unref(maxTokenAmountReached) ? '#DDD5BF' : '#FEF7E5'
                  }]),
                        size: "sm"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('addToken')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "style"])
                    ], 512),
                    _createElementVNode("div", {
                      ref: totalsRowElement,
                      class: "w-full p-2 px-4",
                      style: {"background":"#F6EDD2"}
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('totalAllocated')), 1),
                        _createVNode(_component_BalStack, {
                          horizontal: "",
                          spacing: "xs",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", {
                              class: _normalizeClass(_unref(weightColor))
                            }, _toDisplayString(_unref(totalAllocatedWeight)) + "%", 3),
                            (
                        Number(_unref(totalWeight)) > 100 || Number(_unref(totalWeight)) <= 0
                      )
                              ? (_openBlock(), _createBlock(_component_BalIcon, {
                                  key: 0,
                                  class: "text-red-500 mt-px",
                                  name: "alert-circle",
                                  size: "sm"
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_component_BalProgressBar, {
                        color: _unref(progressBarColor),
                        width: _unref(totalAllocatedWeight),
                        class: "my-2"
                      }, null, 8, ["color", "width"])
                    ], 512)
                  ])
                ], 512)
              ]),
              _: 1
            }),
            _createVNode(AnimatePresence, {
              isVisible: _unref(showLiquidityAlert) && _unref(isWalletReady),
              onOnPresence: onAlertMountChange,
              onOnExit: onAlertMountChange,
              unmountInstantly: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalAlert, {
                  title: _ctx.$t('createAPool.recommendedLiquidity'),
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('createAPool.youCanFundWithThisPoolWith', [
                _unref(fNum2)(_unref(totalLiquidity).toString(), _unref(FNumFormats).fiat)
              ])), 1)
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["isVisible"]),
            _createVNode(AnimatePresence, {
              isVisible: _unref(zeroWeightToken),
              unmountInstantly: "",
              onOnPresence: onAlertMountChange,
              onOnExit: onAlertMountChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalAlert, {
                  title: _ctx.$t('createAPool.zeroWeightTitle'),
                  type: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('createAPool.zeroWeightInfo')), 1)
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["isVisible"]),
            _createVNode(AnimatePresence, {
              isVisible: Number(_unref(totalWeight)) > 100 || Number(_unref(totalWeight)) <= 0,
              unmountInstantly: "",
              onOnPresence: onAlertMountChange,
              onOnExit: onAlertMountChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalAlert, {
                  title: _ctx.$t('createAPool.totalWeightAlertTitle'),
                  type: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('createAPool.totalWeightAlert', [_unref(zeroWeightToken)?.symbol])), 1)
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["isVisible"]),
            _createVNode(AnimatePresence, {
              isVisible: _unref(hasInjectedToken) && !_unref(acceptedCustomTokenDisclaimer),
              unmountInstantly: "",
              onOnPresence: onAlertMountChange,
              onOnExit: onAlertMountChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalAlert, {
                  title: _ctx.$t('tokenWarningTitle'),
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BalStack, {
                      vertical: "",
                      spacing: "xs"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('tokenWarning')), 1),
                        _createElementVNode("div", null, [
                          _createVNode(_component_BalBtn, {
                            onClick: _unref(acceptCustomTokenDisclaimer),
                            size: "xs"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('accept')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _: 1
            }, 8, ["isVisible"]),
            _createVNode(_component_BalBtn, {
              block: "",
              class: "next-button",
              disabled: _unref(isProceedDisabled),
              onClick: handleProceed
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(walletLabel)), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 512))
}
}

})