import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8169d09c")
const _hoisted_1 = { class: "relative flex mr-2 my-1" }
_popScopeId()

import { withDefaults } from 'vue';

import { PoolToken } from '@/services/balancer/subgraph/types';

type Props = {
  tokens: PoolToken[];
  hasBalance: boolean;
  isSelected: boolean;
};


export default _defineComponent({
  props: {
    tokens: { type: Array, required: true },
    hasBalance: { type: Boolean, required: true, default: false },
    isSelected: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  tokens: PoolToken[];
  hasBalance: boolean;
  isSelected: boolean;
}) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'pill',
        {
          'pill-selected': __props.isSelected
        }
      ]),
      style: _normalizeStyle({ zIndex: __props.tokens.length })
    }, _toDisplayString(_ctx.$t('tokenPills.hiddenTokens', [__props.tokens.length])), 7),
    (__props.hasBalance)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "balance-indicator",
          style: _normalizeStyle({ zIndex: __props.tokens.length })
        }, null, 4))
      : _createCommentVNode("", true),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (n) => {
      return _createElementVNode("div", {
        key: n,
        class: _normalizeClass([
        'pill pill-stacked',
        {
          'pill-selected': __props.isSelected
        }
      ]),
        style: _normalizeStyle({
        transform: `translateX(${n * 8}px)`,
        zIndex: __props.tokens.length - n
      })
      }, null, 6)
    }), 64))
  ]))
}
}

})