import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overflow-x-auto whitespace-nowrap" }
const _hoisted_2 = { class: "px-6 py-4 flex flex-row w-52" }
const _hoisted_3 = { class: "pl-4 font-medium eth-address" }
const _hoisted_4 = { class: "px-6 py-4 text-right font-numeric" }
const _hoisted_5 = { class: "px-6 py-4 text-right font-numeric" }
const _hoisted_6 = { class: "px-6 py-4 text-right font-numeric" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalTable = _resolveComponent("BalTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BalTable, {
      columns: _ctx.columns,
      data: _ctx.tableData,
      "is-loading": _ctx.loading,
      "skeleton-class": "h-64",
      sticky: "both",
      "initial-state": {
        sortColumn: 'weight',
        sortDirection: 'desc'
      }
    }, {
      tokenColumnCell: _withCtx((token) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BalLink, {
            href: _ctx.explorer.addressLink(token.address),
            external: "",
            noStyle: "",
            class: "flex items-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalAsset, {
                address: token.address,
                size: 36
              }, null, 8, ["address"]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.symbolFor(token.address)), 1),
              _createVNode(_component_BalIcon, {
                name: "arrow-up-right",
                size: "sm",
                class: "ml-2 text-gray-500 hover:text-red-500 transition-colors"
              })
            ]),
            _: 2
          }, 1032, ["href"])
        ])
      ]),
      tokenWeightCell: _withCtx((token) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.weightFor(token.address)), 1)
      ]),
      tokenBalanceCell: _withCtx((token) => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.balanceFor(token.address)), 1)
      ]),
      tokenValueCell: _withCtx((token) => [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.fiatValueFor(token.address)), 1)
      ]),
      _: 1
    }, 8, ["columns", "data", "is-loading"])
  ]))
}