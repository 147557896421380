import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "-mt-1 flex flex-wrap" }

import { computed, withDefaults } from 'vue';

import useNumbers from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';

import { PoolToken } from '@/services/balancer/subgraph/types';

import WeightedTokenPill from './WeightedTokenPill.vue';
import StableTokenPill from './StableTokenPill.vue';
import HiddenTokensPills from './HiddenTokensPills.vue';

type Props = {
  tokens: PoolToken[];
  isStablePool?: boolean;
  selectedTokens?: string[];
};


export default _defineComponent({
  props: {
    tokens: { type: Array, required: true },
    isStablePool: { type: Boolean, required: false, default: false },
    selectedTokens: { type: Array, required: false, default: () => [] }
  } as unknown as undefined,
  setup(__props: {
  tokens: PoolToken[];
  isStablePool?: boolean;
  selectedTokens?: string[];
}) {

const props = __props


const { fNum2 } = useNumbers();
const { tokens, hasBalance } = useTokens();

/**
 * COMPUTED
 */
const visibleTokens = computed(() => props.tokens.slice(0, MAX_PILLS));

const hiddenTokens = computed(() => props.tokens.slice(MAX_PILLS));

const hasBalanceInHiddenTokens = computed(() =>
  hiddenTokens.value.some(token => hasBalance(token.address))
);

const isSelectedInHiddenTokens = computed(() =>
  hiddenTokens.value.some(token => props.selectedTokens.includes(token.address))
);

/**
 * METHODS
 */
function symbolFor(token: PoolToken): string {
  return token.symbol || tokens.value[token.address]?.symbol || '---';
}

function weightFor(token: PoolToken): string {
  return fNum2(token.weight, {
    style: 'unit',
    unit: 'percent',
    maximumFractionDigits: 0
  });
}

const MAX_PILLS = 11;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.isStablePool)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(visibleTokens), (token) => {
          return (_openBlock(), _createBlock(StableTokenPill, {
            key: token.address,
            hasBalance: _unref(hasBalance)(token.address),
            symbol: symbolFor(token),
            isSelected: __props.selectedTokens.includes(token.address)
          }, null, 8, ["hasBalance", "symbol", "isSelected"]))
        }), 128))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleTokens), (token) => {
            return (_openBlock(), _createBlock(WeightedTokenPill, {
              key: token.address,
              hasBalance: _unref(hasBalance)(token.address),
              symbol: symbolFor(token),
              weight: weightFor(token),
              isSelected: __props.selectedTokens.includes(token.address)
            }, null, 8, ["hasBalance", "symbol", "weight", "isSelected"]))
          }), 128)),
          (_unref(hiddenTokens).length > 0)
            ? (_openBlock(), _createBlock(HiddenTokensPills, {
                key: 0,
                tokens: _unref(hiddenTokens),
                hasBalance: _unref(hasBalanceInHiddenTokens),
                isSelected: _unref(isSelectedInHiddenTokens)
              }, null, 8, ["tokens", "hasBalance", "isSelected"]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})