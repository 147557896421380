import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_4 = {
  key: 0,
  class: "px-6 py-4 flex items-center"
}
const _hoisted_5 = { class: "px-6 py-4 -mt-1 flex justify-end font-numeric" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_TokenPills = _resolveComponent("TokenPills")!
  const _component_BalChip = _resolveComponent("BalChip")!
  const _component_LiquidityAPRTooltip = _resolveComponent("LiquidityAPRTooltip")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    class: "mt-4",
    square: _ctx.upToLargeBreakpoint,
    noBorder: _ctx.upToLargeBreakpoint,
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: _ctx.columns,
        data: _ctx.data,
        "is-loading": _ctx.isLoading,
        "is-loading-more": _ctx.isLoadingMore,
        "skeleton-class": "h-64",
        sticky: "both",
        square: _ctx.upToLargeBreakpoint,
        link: {
        to: 'pool',
        getParams: pool => ({ id: pool.id })
      },
        "on-row-click": _ctx.handleRowClick,
        "is-paginated": _ctx.isPaginated,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loadMore'))),
        "initial-state": {
        sortColumn: 'poolValue',
        sortDirection: 'desc'
      }
      }, {
        iconColumnHeader: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: require('@/assets/images/icons/tokens_black.svg')
            }, null, 8, _hoisted_2)
          ])
        ]),
        iconColumnCell: _withCtx((pool) => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_BalAssetSet, {
                  addresses: _ctx.orderedTokenAddressesFor(pool),
                  width: 100
                }, null, 8, ["addresses"])
              ]))
            : _createCommentVNode("", true)
        ]),
        poolNameCell: _withCtx((pool) => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_TokenPills, {
                  tokens: _ctx.orderedPoolTokens(pool),
                  isStablePool: _ctx.isStableLike(pool.poolType),
                  selectedTokens: _ctx.selectedTokens
                }, null, 8, ["tokens", "isStablePool", "selectedTokens"]),
                (pool.dynamic.isNewPool)
                  ? (_openBlock(), _createBlock(_component_BalChip, {
                      key: 0,
                      color: "red",
                      size: "sm",
                      class: "ml-2 uppercase",
                      outline: false
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('new')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        aprCell: _withCtx((pool) => [
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(Number(pool.dynamic.apr.pool) > 10000
              ? '-'
              : _ctx.fNum2(pool.dynamic.apr.total, _ctx.FNumFormats.percent)) + " ", 1),
            _createVNode(_component_LiquidityAPRTooltip, { pool: pool }, null, 8, ["pool"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "is-loading", "is-loading-more", "square", "link", "on-row-click", "is-paginated"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}