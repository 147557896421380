import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-67cc8b48")
const _hoisted_1 = { class: "summary-table" }
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = {
  class: "flex flex-col py-2",
  style: {"border-color":"#d8ceb5"}
}
const _hoisted_4 = { class: "summary-table-row" }
const _hoisted_5 = { class: "summary-table-label" }
const _hoisted_6 = { class: "summary-table-number" }
const _hoisted_7 = { class: "summary-table-row" }
const _hoisted_8 = { class: "summary-table-label" }
const _hoisted_9 = { class: "summary-table-number" }
const _hoisted_10 = { class: "summary-table-row" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "summary-table-number" }
const _hoisted_13 = {
  class: "p-2 rounded-t-lg border-b",
  style: {"border-color":"#d8ceb5"}
}
const _hoisted_14 = ["textContent"]
const _hoisted_15 = { class: "ml-1 text-gray-500" }
const _hoisted_16 = { class: "text-base font-semibold mt-1" }
const _hoisted_17 = { class: "p-2" }
const _hoisted_18 = { class: "whitespace-nowrap flex items-center mb-1" }
const _hoisted_19 = { class: "ml-1 text-gray-500 text-xs" }
const _hoisted_20 = { class: "flex items-center" }
const _hoisted_21 = { class: "ml-1 text-gray-500 text-xs" }
const _hoisted_22 = { class: "text-gray-500 text-xs ml-1" }
const _hoisted_23 = { class: "flex items-center" }
const _hoisted_24 = { class: "ml-1 text-gray-500" }
const _hoisted_25 = { class: "text-gray-500 ml-1" }
_popScopeId()

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { isStablePhantom, isWstETH } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import useUserSettings from '@/composables/useUserSettings';
import { bnum } from '@/lib/utils';
import { FullPool } from '@/services/balancer/subgraph/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  fiatTotal: string;
  priceImpact: number;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    fiatTotal: { type: String, required: true },
    priceImpact: { type: Number, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  fiatTotal: string;
  priceImpact: number;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { currency } = useUserSettings();
const { t } = useI18n();
const { getTokens } = useTokens();

/**
 * COMPUTED
 */
const totalWeeklyYield = computed((): string =>
  weeklyYieldForAPR(props.pool.dynamic.apr.total)
);

const swapFeeWeeklyYield = computed((): string =>
  weeklyYieldForAPR(props.pool.dynamic.apr.pool)
);

const thirdPartyWeeklyYield = computed((): string =>
  weeklyYieldForAPR(props.pool.dynamic.apr.thirdParty)
);

const lmWeeklyYield = computed((): string =>
  weeklyYieldForAPR(props.pool.dynamic.apr.liquidityMining)
);

const lmBreakdown = computed(
  () => props.pool.dynamic.apr.liquidityMiningBreakdown
);

const lmTokens = computed(() => getTokens(Object.keys(lmBreakdown.value)));

const lmMultiRewardPool = computed(
  () => Object.keys(lmTokens.value).length > 1
);

const hasThirdPartyAPR = computed(() =>
  bnum(props.pool.dynamic.apr.thirdParty).gt(0)
);

const thirdPartyBreakdown = computed(
  () => props.pool.dynamic.apr.thirdPartyBreakdown
);

const thirdPartyTokens = computed(() =>
  getTokens(Object.keys(thirdPartyBreakdown.value))
);

const thirdPartyMultiRewardPool = computed(
  () => Object.keys(thirdPartyTokens.value).length > 1
);

const thirdPartyFiatLabel = computed(() => {
  if (isWstETH(props.pool)) return t('thirdPartyRewards.fiat.steth');
  if (isStablePhantom(props.pool.poolType))
    return t('thirdPartyRewards.fiat.aaveBoosted');

  return '';
});

/**
 * METHODS
 */
function weeklyYieldForAPR(apr: string): string {
  return bnum(apr)
    .times(props.fiatTotal)
    .div(52)
    .toString();
}

return (_ctx: any,_cache: any) => {
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_StarsIcon = _resolveComponent("StarsIcon")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('summary')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('total')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(_unref(fNum2)(__props.fiatTotal, _unref(FNumFormats).fiat)) + " ", 1),
          _createVNode(_component_BalTooltip, {
            text: _ctx.$t('tooltips.invest.total', [_unref(currency).toUpperCase()]),
            "icon-size": "sm",
            class: "ml-2"
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('priceImpact')), 1),
        _createElementVNode("div", _hoisted_9, [
          _createTextVNode(_toDisplayString(_unref(fNum2)(__props.priceImpact, _unref(FNumFormats).percent)) + " ", 1),
          _createVNode(_component_BalTooltip, {
            text: _ctx.$t('tooltips.invest.priceImpact'),
            "icon-size": "sm",
            width: "72",
            class: "ml-2"
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: "summary-table-label",
          textContent: _toDisplayString(_ctx.$t('potentialWeeklyYield'))
        }, null, 8, _hoisted_11),
        _createElementVNode("div", _hoisted_12, [
          _createTextVNode(_toDisplayString(_unref(fNum2)(_unref(totalWeeklyYield), _unref(FNumFormats).fiat)) + " ", 1),
          _createVNode(_component_BalTooltip, {
            "icon-size": "sm",
            width: "72",
            noPad: ""
          }, {
            activator: _withCtx(() => [
              (props.pool.hasLiquidityMiningRewards || _unref(hasThirdPartyAPR))
                ? (_openBlock(), _createBlock(_component_StarsIcon, {
                    key: 0,
                    class: "h-4 text-yellow-300"
                  }))
                : (_openBlock(), _createBlock(_component_BalIcon, {
                    key: 1,
                    name: "info",
                    size: "sm",
                    class: "ml-2",
                    style: {"color":"#d8ceb5"}
                  }))
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", {
                  class: "text-sm",
                  textContent: _toDisplayString(_ctx.$t('yieldEarnings'))
                }, null, 8, _hoisted_14),
                _createElementVNode("span", _hoisted_15, " (" + _toDisplayString(_ctx.$t('basedOnLast24h')) + ") ", 1),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(fNum2)(_unref(totalWeeklyYield), _unref(FNumFormats).fiat)) + " " + _toDisplayString(_ctx.$t('perWeek')), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createTextVNode(_toDisplayString(_unref(fNum2)(_unref(swapFeeWeeklyYield), _unref(FNumFormats).fiat)) + " ", 1),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('swapFee')), 1)
                ]),
                (_unref(hasThirdPartyAPR))
                  ? (_openBlock(), _createBlock(_component_BalBreakdown, {
                      key: 0,
                      items: Object.entries(_unref(thirdPartyBreakdown)),
                      hideItems: !_unref(thirdPartyMultiRewardPool)
                    }, _createSlots({
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, [
                          _createTextVNode(_toDisplayString(_unref(fNum2)(_unref(thirdPartyWeeklyYield), _unref(FNumFormats).fiat)) + " ", 1),
                          _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(thirdPartyFiatLabel)), 1)
                        ])
                      ]),
                      _: 2
                    }, [
                      (_unref(thirdPartyMultiRewardPool))
                        ? {
                            name: "item",
                            fn: _withCtx(({ item }) => [
                              _createTextVNode(_toDisplayString(_unref(fNum2)(weeklyYieldForAPR(item[1]), _unref(FNumFormats).fiat)) + " ", 1),
                              _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(thirdPartyTokens)[item[0]].symbol), 1)
                            ])
                          }
                        : undefined
                    ]), 1032, ["items", "hideItems"]))
                  : _createCommentVNode("", true),
                (props.pool.hasLiquidityMiningRewards)
                  ? (_openBlock(), _createBlock(_component_BalBreakdown, {
                      key: 1,
                      items: Object.entries(_unref(lmBreakdown)),
                      hideItems: !_unref(lmMultiRewardPool)
                    }, _createSlots({
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("span", null, _toDisplayString(_unref(fNum2)(_unref(lmWeeklyYield), _unref(FNumFormats).fiat)), 1),
                          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('liquidityMining')), 1)
                        ])
                      ]),
                      _: 2
                    }, [
                      (_unref(lmMultiRewardPool))
                        ? {
                            name: "item",
                            fn: _withCtx(({ item }) => [
                              _createTextVNode(_toDisplayString(_unref(fNum2)(weeklyYieldForAPR(item[1]), _unref(FNumFormats).fiat)) + " ", 1),
                              _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(lmTokens)[item[0]].symbol), 1)
                            ])
                          }
                        : undefined
                    ]), 1032, ["items", "hideItems"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})