import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "overflow-x-auto whitespace-nowrap" }
const _hoisted_2 = { class: "p-4 -mt-2" }



import { FullPool } from '@/services/balancer/subgraph/types';
import useWeb3 from '@/services/web3/useWeb3';

import useBreakpoints from '@/composables/useBreakpoints';

import AssetRow from './components/AssetRow';
import { bnum } from '@/lib/utils';

/**
 * TYPES
 */
type Props = {
  pool: FullPool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: FullPool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { upToLargeBreakpoint } = useBreakpoints();
const { explorerLinks } = useWeb3();

/**
 * METHODS
 */
function getUnderlyingTokens(address: string) {
  const linearPools = props.pool.onchain.linearPools;

  if (linearPools == null) {
    return [];
  }

  const mainTokenAddress = linearPools[address].mainToken.address;

  return linearPools != null
    ? [
        linearPools[address].mainToken,
        {
          ...linearPools[address].wrappedToken,
          mainTokenAddress
        }
      ]
    : [];
}

function getTokenShare(address: string) {
  const linearPools = props.pool.onchain.linearPools;

  if (linearPools == null) {
    return null;
  }

  const token = props.pool.onchain.tokens[address];

  return bnum(token.balance)
    .div(linearPools[address].totalSupply)
    .toString();
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.pool.tokenAddresses, (address) => {
        return (_openBlock(), _createElementBlock("div", {
          key: address,
          class: "py-4"
        }, [
          _createVNode(_component_BalBreakdown, {
            items: getUnderlyingTokens(address),
            class: "w-full",
            offsetClassOverrides: "mt-4 ml-3",
            initVertBarClassOverrides: "h-6 -mt-6",
            size: "lg"
          }, {
            item: _withCtx(({ item: asset }) => [
              _createVNode(_unref(AssetRow), {
                address: asset.address,
                "main-token-address": asset.mainTokenAddress,
                balance: asset.balance,
                "price-rate": asset.priceRate,
                share: getTokenShare(address)
              }, null, 8, ["address", "main-token-address", "balance", "price-rate", "share"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_BalLink, {
                href: _unref(explorerLinks).addressLink(address),
                external: "",
                noStyle: "",
                class: "flex items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalAsset, {
                    address: address,
                    class: "mr-2"
                  }, null, 8, ["address"]),
                  _createTextVNode(" " + _toDisplayString(__props.pool.onchain.tokens[address].symbol) + " ", 1),
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "ml-2 text-gray-500 hover:text-red-500 transition-colors"
                  })
                ]),
                _: 2
              }, 1032, ["href"])
            ]),
            _: 2
          }, 1032, ["items"])
        ]))
      }), 128))
    ])
  ]))
}
}

})