
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavTabs',

  props: ['tabs', 'currentTab', 'classes'],
  setup() {
    const activeClasses = 'active text-white rounded-lg';

    return {
      activeClasses
    };
  }
});
