import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-38b4611b")
const _hoisted_1 = { class: "graph-modal" }
_popScopeId()

import { computed, onMounted, ref } from 'vue';
import { ENABLE_LEGACY_TRADE_INTERFACE } from '@/composables/trade/constants';
// Types
import { TradeInterface } from '@/store/modules/app';
// Composables
import { useStore } from 'vuex';
import useBreakpoints from '@/composables/useBreakpoints';
import useTokenLists from '@/composables/useTokenLists';
import usePoolFilters from '@/composables/pools/usePoolFilters';
// Components
import MyWallet from '@/components/cards/MyWallet/MyWallet.vue';
import TrendingPairs from '@/components/cards/TrendingPairs/TrendingPairs.vue';
import PairPriceGraph from '@/components/cards/PairPriceGraph/PairPriceGraph.vue';
import TradeCardGP from '@/components/cards/TradeCardGP/TradeCardGP.vue';
import Col3Layout from '@/components/layouts/Col3Layout.vue';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const showPriceGraphModal = ref(false);

/**
 * COMPOSABLES
 */
const store = useStore();
const { loadingTokenLists } = useTokenLists();
const { setSelectedTokens } = usePoolFilters();
const { upToLargeBreakpoint } = useBreakpoints();

/**
 * COMPUTED
 */
const appLoading = computed(() => store.state.app.loading);
const tradeInterface = computed(() => store.state.app.tradeInterface);

/**
 * METHODS
 */
function onPriceGraphModalClose() {
  showPriceGraphModal.value = false;
}

function togglePairPriceGraphModal() {
  showPriceGraphModal.value = !showPriceGraphModal.value;
}

/**
 * CALLBACKS
 */
onMounted(() => {
  // selectedPoolTokens are only persisted between the Home/Pool pages
  setSelectedTokens([]);
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalModal = _resolveComponent("BalModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Col3Layout, {
      offsetGutters: "",
      mobileHideGutters: "",
      class: "mt-8"
    }, {
      default: _withCtx(() => [
        (_unref(appLoading) || _unref(loadingTokenLists))
          ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
              key: 0,
              class: "h-96"
            }))
          : (_openBlock(), _createBlock(TradeCardGP, { key: 1 }))
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      _createVNode(_component_BalModal, {
        show: showPriceGraphModal.value,
        onClose: onPriceGraphModalClose
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(PairPriceGraph, {
              toggleModal: togglePairPriceGraphModal,
              isModal: "",
              onCloseModal: onPriceGraphModalClose
            })
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ]))
  ], 64))
}
}

})