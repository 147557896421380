import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = {
  class: "text-sm divide-y",
  style: {"border-color":"#d8ceb5"}
}
const _hoisted_3 = {
  class: "px-3 pt-3 pb-1 rounded-t",
  style: {"background":"#f2e9d1"}
}
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "text-lg" }
const _hoisted_6 = {
  class: "p-3",
  style: {"background":"#fef7e5","border-color":"#d8ceb5","overflow":"hidden"}
}
const _hoisted_7 = { class: "whitespace-nowrap flex items-center mb-1" }
const _hoisted_8 = { class: "ml-1 text-gray-500 text-xs" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { isStablePhantom, isWstETH } from '@/composables/usePool';
import { APR_THRESHOLD } from '@/constants/poolAPR';

import { DecoratedPool } from '@/services/balancer/subgraph/types';
import { bnum } from '@/lib/utils';

/**
 * TYPES
 */
type Props = {
  pool: DecoratedPool;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: DecoratedPool;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { getTokens } = useTokens();
const { t } = useI18n();

/**
 * COMPUTED
 */
const lmBreakdown = computed(
  () => props.pool.dynamic.apr.liquidityMiningBreakdown
);

const validAPR = computed(
  () => Number(props.pool.dynamic.apr.total) * 100 <= APR_THRESHOLD
);

const lmTokens = computed(() => getTokens(Object.keys(lmBreakdown.value)));

const lmMultiRewardPool = computed(
  () => Object.keys(lmTokens.value).length > 1
);

const hasThirdPartyAPR = computed(() =>
  bnum(props.pool.dynamic.apr.thirdParty).gt(0)
);

const thirdPartyBreakdown = computed(
  () => props.pool.dynamic.apr.thirdPartyBreakdown
);

const thirdPartyTokens = computed(() =>
  getTokens(Object.keys(thirdPartyBreakdown.value))
);

const thirdPartyMultiRewardPool = computed(
  () => Object.keys(thirdPartyTokens.value).length > 1
);

const thirdPartyAPRLabel = computed(() => {
  if (isWstETH(props.pool)) return t('thirdPartyRewards.apr.steth');
  if (isStablePhantom(props.pool.poolType))
    return t('thirdPartyRewards.apr.aaveBoosted');

  return '';
});

return (_ctx: any,_cache: any) => {
  const _component_StarsIcon = _resolveComponent("StarsIcon")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_unref(validAPR))
    ? (_openBlock(), _createBlock(_component_BalTooltip, {
        key: 0,
        width: "auto",
        noPad: ""
      }, {
        activator: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (__props.pool.hasLiquidityMiningRewards || _unref(hasThirdPartyAPR))
              ? (_openBlock(), _createBlock(_component_StarsIcon, _mergeProps({
                  key: 0,
                  class: "h-5 text-yellow-300"
                }, _ctx.$attrs), null, 16))
              : (_openBlock(), _createBlock(_component_BalIcon, _mergeProps({
                  key: 1,
                  name: "info",
                  size: "sm",
                  class: "text-gray-400 dark:text-gray-500"
                }, _ctx.$attrs), null, 16))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('totalAPR')), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(fNum2)(__props.pool.dynamic.apr.total, _unref(FNumFormats).percent)), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createTextVNode(_toDisplayString(_unref(fNum2)(__props.pool.dynamic.apr.pool, _unref(FNumFormats).percent)) + " ", 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('swapFeeAPR')), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})