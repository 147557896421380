import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6b3f4244")
const _hoisted_1 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`tab-nav-toggle bg-custom-gray-200 ${_ctx.classes}`)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tab.id,
        onClick: ($event: any) => (_ctx.$emit('tab-switched', tab.id)),
        class: _normalizeClass([
        'toggle-link px-4 rounded-sm text-sm',
        { [_ctx.activeClasses]: tab.id === _ctx.currentTab }
      ])
      }, _toDisplayString(tab.name), 11, _hoisted_1))
    }), 128))
  ], 2))
}