import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "text-gray-700 dark:text-white text-base" }
const _hoisted_3 = { class: "capitalize" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "wallet-connect-btn",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectWallet(_ctx.wallet)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.WalletNameMap[_ctx.wallet]), 1)
      ]),
      _createElementVNode("img", {
        src: require(`@/assets/images/connectors/${_ctx.wallet}.svg`),
        class: "h-10 w-10 ml-4",
        style: {"margin-left":"auto"}
      }, null, 8, _hoisted_4)
    ])
  ]))
}