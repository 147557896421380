import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createBlock(_component_BalBtn, _mergeProps({
        key: option.value,
        onClick: ($event: any) => (_ctx.onSelect(option)),
        outline: "",
        size: "sm",
        class: "capitalize mr-2 w-18",
        style: [{"border-color":"#dedde1","border-radius":"4px"}, {
        background: _ctx.modelValue === option.value ? '#AC503F' : 'white',
        boxShadow:
          _ctx.modelValue === option.value ? '0px 0px 0px 2px #F7CCC4' : 'none',
        border:
          _ctx.modelValue === option.value
            ? '1px solid #F7CCC4'
            : '1px solid #dedde1'
      }]
      }, _ctx.attrs_, {
        color: _ctx.modelValue === option.value ? 'white' : 'black'
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString('best' === option.label ? _ctx.$t(option.label) : option.label), 1)
        ]),
        _: 2
      }, 1040, ["onClick", "style", "color"]))
    }), 128))
  ]))
}