import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a1bceb70")
const _hoisted_1 = { class: "progress-track" }
_popScopeId()

import { computed } from 'vue';

/**
 * TYPES
 */
type Props = {
  width: string | number;
  bufferWidth: string | number;
  size?: string;
  color?: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    width: { type: [String, Number], required: true },
    bufferWidth: { type: [String, Number], required: true },
    size: { type: String, required: false, default: '1' },
    color: { type: String, required: false, default: 'green' }
  } as unknown as undefined,
  setup(__props: {
  width: string | number;
  bufferWidth: string | number;
  size?: string;
  color?: string;
}) {

const props = __props


/**
 * COMPUTED
 */
const barClasses = computed(() => ({
  [`h-${props.size}`]: true,
  [`bg-${props.color}-400`]: true
}));

const bufferBarClasses = computed(() => ({
  [`h-${props.size}`]: true,
  [`bg-yellow-500`]: true
}));

const barStyles = computed(() => ({
  width: `${props.width}%`
}));

const bufferBarStyles = computed(() => ({
  width: `${props.bufferWidth}%`
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['progress-bar', _unref(barClasses)]),
      style: _normalizeStyle(_unref(barStyles))
    }, null, 6),
    (props.bufferWidth != null && props.bufferWidth > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['progress-bar', _unref(bufferBarClasses)]),
          style: _normalizeStyle(_unref(bufferBarStyles))
        }, null, 6))
      : _createCommentVNode("", true)
  ]))
}
}

})