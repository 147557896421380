import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex items-center justify-between" }

import { onBeforeMount } from 'vue';
import { configService } from '@/services/config/config.service';
// Components
import InvestForm from '@/components/forms/pool_actions/InvestForm/InvestForm.vue';
import TradeSettingsPopover, {
  TradeSettingsContext
} from '@/components/popovers/TradeSettingsPopover.vue';
// Composables
import usePoolTransfers from '@/composables/contextual/pool-transfers/usePoolTransfers';
import { usePool } from '@/composables/usePool';
import { forChange } from '@/lib/utils';
import useInvestState from '@/components/forms/pool_actions/InvestForm/composables/useInvestState';

/**
 * STATE
 */

export default _defineComponent({
  setup(__props) {

const { network } = configService;
const { pool, loadingPool, transfersAllowed } = usePoolTransfers();
const { isStablePhantomPool } = usePool(pool);
const { sor, sorReady } = useInvestState();

/**
 * CALLBACKS
 */
onBeforeMount(async () => {
  await forChange(loadingPool, false);

  if (pool.value && isStablePhantomPool.value) {
    // Initialise SOR for batch swap queries
    sorReady.value = await sor.fetchPools();
  } else {
    sorReady.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(loadingPool) || !_unref(transfersAllowed) || !_unref(sorReady))
      ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
          key: 0,
          class: "h-96"
        }))
      : (_openBlock(), _createBlock(_component_BalCard, {
          key: 1,
          shadow: "xl",
          exposeOverflow: "",
          noBorder: ""
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('investInPool')), 1)
              ])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(InvestForm, { pool: _unref(pool) }, null, 8, ["pool"])
          ]),
          _: 1
        }))
  ]))
}
}

})