import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-169dd267")
const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNavActivityBtn = _resolveComponent("AppNavActivityBtn")!
  const _component_AppNavClaimBtn = _resolveComponent("AppNavClaimBtn")!
  const _component_AppNavAccountBtn = _resolveComponent("AppNavAccountBtn")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.account)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppNavActivityBtn),
          (_ctx.liquidityMiningSupported)
            ? (_openBlock(), _createBlock(_component_AppNavClaimBtn, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_AppNavAccountBtn)
        ]))
      : (_openBlock(), _createBlock(_component_BalBtn, {
          key: 1,
          color: "red",
          class: "connect-wallet-btn",
          onClick: _ctx.toggleWalletSelectModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "hidden lg:inline-block",
              textContent: _toDisplayString(_ctx.$t('connectWallet'))
            }, null, 8, _hoisted_2),
            _createElementVNode("span", {
              class: "lg:hidden",
              textContent: _toDisplayString(_ctx.$t('connect'))
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["onClick"]))
  ]))
}