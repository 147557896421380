import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, Teleport as _Teleport, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-576b7eb2")
const _hoisted_1 = { class: "token-select-input-container" }
const _hoisted_2 = { class: "w-8" }
const _hoisted_3 = { class: "text-base font-medium" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500 ml-2"
}
const _hoisted_5 = {
  class: "token-select-input selected group selectable",
  style: {"background":"#FEF7E5","border":"1px solid #D8CEB5","border-radius":"10px","box-sizing":"border-box","box-shadow":"0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.02)"}
}
const _hoisted_6 = { class: "w-8" }
const _hoisted_7 = { class: "text-base font-medium" }
const _hoisted_8 = {
  key: 0,
  class: "text-gray-500 ml-2"
}
const _hoisted_9 = ["set"]
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "ml-1 font-medium" }
_popScopeId()

import { ref, computed } from 'vue';
import SelectTokenModal from '@/components/modals/SelectTokenModal/SelectTokenModal.vue';
import useTokens from '@/composables/useTokens';
import useNumbers from '@/composables/useNumbers';
import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  modelValue: string;
  fixed?: boolean;
  weight?: number | string;
  excludedTokens?: string[];
  options?: string[];
  disableInjection?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    modelValue: { type: String, required: true, default: '' },
    fixed: { type: Boolean, required: false, default: false },
    weight: { type: [Number, String], required: false, default: 0 },
    excludedTokens: { type: Array, required: false, default: () => [] },
    options: { type: Array, required: false, default: () => [] },
    disableInjection: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["update:modelValue"] as unknown as undefined,
  setup(__props: {
  modelValue: string;
  fixed?: boolean;
  weight?: number | string;
  excludedTokens?: string[];
  options?: string[];
  disableInjection?: boolean;
}, { emit }: { emit: ({
  (e: 'update:modelValue', value: string): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props




/**
 * STATE
 */
const openTokenModal = ref(false);

/**
 * COMPOSABLEs
 */
const { getToken } = useTokens();
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const hasToken = computed(() => !!props.modelValue);

const token = computed((): TokenInfo | null => {
  if (!hasToken.value) return null;
  return getToken(props.modelValue);
});

/**
 * METHODS
 */
function toggleModal(): void {
  if (!props.fixed) openTokenModal.value = !openTokenModal.value;
}

function tokenFor(option: string): TokenInfo {
  return getToken(option);
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalDropdown = _resolveComponent("BalDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(hasToken) && __props.options.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['token-select-input selected group', { selectable: !__props.fixed }]),
          style: {"background":"#FEF7E5","border":"1px solid #D8CEB5","border-radius":"10px","box-sizing":"border-box","box-shadow":"0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.02)"},
          onClick: toggleModal
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BalAsset, {
              address: _unref(token)?.address,
              class: "shadow"
            }, null, 8, ["address"])
          ]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(token)?.symbol), 1),
          (Number(__props.weight) > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(fNum2)(__props.weight, {
            style: 'unit',
            unit: 'percent',
            maximumFractionDigits: 0
          })), 1))
            : _createCommentVNode("", true),
          (!__props.fixed)
            ? (_openBlock(), _createBlock(_component_BalIcon, {
                key: 1,
                name: "chevron-down",
                size: "sm",
                class: "ml-2",
                style: {"color":"black"}
              }))
            : _createCommentVNode("", true)
        ], 2))
      : (_unref(hasToken) && __props.fixed && __props.options.length > 0)
        ? (_openBlock(), _createBlock(_component_BalDropdown, {
            key: 1,
            options: __props.options,
            minWidth: "40",
            onSelected: _cache[0] || (_cache[0] = ($event: any) => (emit('update:modelValue', $event))),
            class: "dropdown"
          }, {
            activator: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_BalAsset, {
                    address: _unref(token)?.address,
                    class: "shadow"
                  }, null, 8, ["address"])
                ]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(token)?.symbol), 1),
                (Number(__props.weight) > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(fNum2)(__props.weight, {
                style: 'unit',
                unit: 'percent',
                maximumFractionDigits: 0
              })), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_BalIcon, {
                  name: "chevron-down",
                  size: "sm",
                  class: "text-black ml-2"
                })
              ])
            ]),
            option: _withCtx(({ option: address }) => [
              _createElementVNode("div", {
                set: (_ctx.optionToken = tokenFor(address) || {}),
                class: "flex items-center justify-between"
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_BalAsset, {
                    address: _ctx.optionToken?.address,
                    class: "shadow"
                  }, null, 8, ["address"]),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.optionToken?.symbol), 1)
                ]),
                (_ctx.optionToken.address === __props.modelValue)
                  ? (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 0,
                      name: "check",
                      class: "text-red-500 ml-4"
                    }))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_9)
            ]),
            _: 1
          }, 8, ["options"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "token-select-input unselected selectable",
            style: {"background":"#FEF7E5","color":"black","border":"1px solid #D8CEB5","border-radius":"10px","box-sizing":"border-box","box-shadow":"0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.02)"},
            onClick: toggleModal
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('selectToken')) + " ", 1),
            _createVNode(_component_BalIcon, {
              name: "chevron-down",
              size: "sm",
              class: "ml-2"
            })
          ])),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (openTokenModal.value)
        ? (_openBlock(), _createBlock(SelectTokenModal, {
            key: 0,
            excludedTokens: [...__props.excludedTokens, __props.modelValue],
            includeEther: true,
            disableInjection: __props.disableInjection,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (openTokenModal.value = false)),
            onSelect: _cache[2] || (_cache[2] = ($event: any) => (emit('update:modelValue', $event)))
          }, null, 8, ["excludedTokens", "disableInjection"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})